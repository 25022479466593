<template>
  <BasePageSection
    :bg-image="homeHero"
    class="home-hero"
    has-overlay
    full-width
    full-bleed-bg
    py-5
    fluid
  >
    <!-- TODO (ESS) : add vue qm adjustment for justify -->
    <WithLocationSummaries v-slot="{ locations }">
      <VLayout v-if="locations[0]" row wrap>
        <VFlex
          class="text-xs-left"
          lg5
          md6
          offset-md1
          sm8
          offset-lg2
          xs12
          right
        >
          <VCard
            :class="['hero-cta elevation-0 py-4', `px-${heroPaddingAmount}`]"
          >
            <h2 class="hero-title bluegiant-red--text header-4">{{
              getSiteTitle
            }}</h2>

            <div class="hero-text white--text"
              >{{ locations[0].contactinfo['street_1'] }}<br />
              {{
                `${locations[0].contactinfo['city']}, ${locations[0].contactinfo['state']}`
              }}<br />{{ locations[0].contactinfo.phone_number }}</div
            >
            <div
              v-if="locations[0].hours_for_humans.length"
              class="hero-text hero-hours white--text"
              >{{ locations[0].hours_for_humans }}</div
            >
          </VCard>
        </VFlex>
        <VFlex
          lg5
          md5
          sm4
          xs12
          right
          align-self-center
          text-xs-left
          :class="[{ 'pl-2': $mq === 'xs' }, { 'pl-4': $mq === 'sm' }]"
        >
          <BaseButtonOutline
            to="/menu"
            class="ml-0 py-5"
            color="rgba(0,0,0,.65)"
            :large="!['xs', 'sm', 'md'].includes($mq)"
          >
            See The Menu
          </BaseButtonOutline>
        </VFlex>
      </VLayout>
    </WithLocationSummaries>
    <BaseThemeComponent name="RedBreak" />
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'
import getSiteAsset from '@utils/get-site-asset'
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'SectionHomeHero',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle', 'getSiteId']),
    ...mapGetters('locations', ['getLocationBySlug']),
    heroPaddingAmount() {
      return this.$mq === 'xs' ? '2' : this.$mq === 'sm' ? '4' : '5'
    }, // heroPaddingAmount
    theLocation() {
      return this.getLocationBySlug('blue-giant')
    }, // theLocation
    homeHero() {
      return getSiteAsset(`site-${this.getSiteId}-home-hero-bg.jpg`)
    }, // homeHero
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.theLocation, key)
    }, // getContactDetail
  },
}
</script>

<style lang="scss" scoped>
@import '@design';

.home-hero {
  position: relative;
}

.v-card.hero-cta {
  background-color: transparent;
}
.hero-title.header-4 {
  // TODO (ESS) : dont hard code
  padding-bottom: 0.15em;
  margin: 0 0 0.3em;
  text-transform: uppercase;
}

.hero-text {
  // TODO (ESS) : dont hard code
  margin-bottom: 1em;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.034em;

  @media only screen and (max-width: 414px) {
    font-size: 1.3rem;
  }
}

.hero-hours {
  @media only screen and (min-width: 600px) {
    white-space: pre;
  }
}
</style>
